<!-- V1.0 base version updated by Raja Mohamed on 18-Sep-20
1.using activity module the sysadmin can add/edit/delete the activities.-->
<template>
  <v-container>
    
   
    
    <Snackbar :snackbar="snackbar" :snackbartext="snackbartext" @setflasesnackbar="snackbar = false"> </Snackbar>
    <v-data-table
      :headers="headers"
      :items="jobs"
      item-key="uid"
      class="elevation-1"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :show-expand="expandflag"
      :items-per-page="roles.itemsPerPage"
      :loading="notloaded"
      loading-text="Loading..."
      :search="search"
      :footer-props="{ prevIcon: 'mdi-arrow-left', nextIcon: 'mdi-arrow-right' }"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
    >
      <template v-slot:[`item.createdOn`]="{ item }">
        {{ convertDate(item.createdOn) }}
      </template>

      <template v-slot:[`item.modifiedOn`]="{ item }">
        {{ convertDate(item.modifiedOn) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Jobs</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
          <v-dialog v-model="dialog" max-width="1000px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2 mx-4" v-bind="attrs" v-on="on">ADD Job</v-btn
              ><!-- add button to add activity-->
            </template>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                     <Alertmsg v-if="alertflag" :alertmsg="alertmsg" @setflasealert="alertflag = false"> </Alertmsg>
   
                  
                    <v-row
                      ><!-- common for add and edit -->
                      <v-col cols="12" xs="2" sm="2" md="1" lg="1">
                        <v-text-field v-if="editedIndex == -1 " v-model="editedItem.jobid" :rules="inputrequired" label="JobID"></v-text-field>
                         <div v-else>
                          <div>Job ID</div>
                          {{ editedItem.jobid }}
                        </div>
                      </v-col>

                      <v-col cols="12" xs="4" sm="4" md="3" lg="3">
                        <v-text-field v-model="editedItem.title" :rules="inputrequired" label="Title"></v-text-field>
                      </v-col>

                      <v-col cols="12" xs="10" sm="10" md="5" lg="5">
                        <v-text-field
                          v-model="editedItem.descriptions"
                          :rules="inputrequired"
                          label="Descriptions"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" xs="6" sm="6" md="3" lg="3">
                        <v-select v-model="editedItem.test" :items="tests" :rules="inputrequired" label="Test">
                        </v-select>
                      </v-col>

                      <v-col cols="12" xs="6" sm="6" md="3" lg="3">
                        <v-text-field
                          v-model="editedItem.totalOpen"
                          :rules="inputrequired"
                          label="Total Openings"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                        <v-select v-model="editedItem.status" :items="jobStatus" :rules="inputrequired" label="Status">
                        </v-select>
                      </v-col>

                      <v-col v-if="editedItem.processflow" cols="12" xs="4" sm="4" md="3" lg="3">
                        <v-select
                          v-model="editedItem.processflow.flow1"
                          :items="processflow"
                          :rules="inputrequired"
                          label="Flow 1"
                        >
                        </v-select>
                      </v-col>

                      <v-col v-if="editedItem.processflow" cols="12" xs="4" sm="4" md="3" lg="3">
                        <v-select
                          v-model="editedItem.processflow.flow2"
                          :items="processflow"
                          :rules="inputrequired"
                          label="Flow 2"
                        >
                        </v-select>
                      </v-col>

                      <v-col v-if="editedItem.processflow" cols="12" xs="4" sm="4" md="3" lg="3">
                        <v-select v-model="editedItem.processflow.flow3" :items="processflow" label="Flow 3">
                        </v-select>
                      </v-col>

                      <v-col v-if="editedItem.processflow" cols="12" xs="4" sm="4" md="3" lg="3">
                        <v-select v-model="editedItem.processflow.flow4" :items="processflow" label="Flow 4">
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" v-if="!spinner" :disabled="!valid" text @click="saveitem">Save</v-btn>
                  <Spinner v-if="spinner" />
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-toolbar>
      </template>

      <!--<template v-slot:[`item.data-table-expand`]="{ expand, isExpanded ,}">
        <v-icon @click="expand(!isExpanded)">expand</v-icon>
      </template>-->
      <!-- <template v-slot:[`item.data-table-expand`]="{ slot,expand}">
        <v-icon @click="expand( slot.expand(!slot.isExpanded))">mdi-arrow-down</v-icon>
      </template>-->

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row v-if="item.processflow">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12">
              <div>
                <h4>Process flow</h4>
                <span>
                  {{ item.processflow.flow1 }} , {{ item.processflow.flow2 }} , {{ item.processflow.flow3 }} ,
                  {{ item.processflow.flow4 }}</span
                >
              </div>
            </v-col>
          </v-row>
        </td>
      </template>

      <template v-if="editflag" v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click.stop="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click.stop="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <div><h2 class="blue">No data Found- Please add Jobs</h2></div>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { jobsRef } from "@/fb"
import { formMixin } from "@/mixins/formMixin.js"
import { convertAll } from "@/mixins/convertall.js"
import Snackbar from "@/components/common/snackbar.vue"
import Alertmsg from "@/components/common/Alertmsg.vue"
import Spinner from "@/components/common/spinner.vue"
import { collectionsOperation } from "@/mixins/collectionsOperations.js"
import {mapState} from "vuex"

export default {
  name: "jobs",
  //  props:['roles'],
  mixins: [formMixin, convertAll,collectionsOperation], // for confirmation while delete
  components: {
  Snackbar,Alertmsg,Spinner,
  },
  data: () => ({
    dialog: false,
    menuls: false, // lastService date
    menuns: false, // nextService date
    search: "", // for data table search
    domain: "",
    notloaded: true, // will be made false once the data-table items fetched from db. till that time loading please wait message will be shown to the user
    lazy: true,
    valid: true,
    expanded: [],
    expandflag: true,
    singleExpand: false,
    headers: [
      // data table headers
      {
        text: "JobID",
        align: "start",
        sortable: true,
        value: "jobid",
      },
      { text: "Title", value: "title", sortable: true },
      { text: "Descriptions", value: "descriptions", sortable: true },
      { text: "Total Openings", value: "totalOpen", sortable: true },
      { text: "Test", value: "test", sortable: true },
      { text: "Status", value: "status", sortable: true },
      { text: "Created On", value: "createdOn", sortable: true },
      { text: "Changed On", value: "modifiedOn", sortable: true },

      { text: "Actions", value: "actions", sortable: false },
    ],
    processflow: ["", "Test", "Manager Interview", "Client1 Interview", "Client2 Interview"],

    jobs: [], // activies array
    editedIndex: -1,
    editedItem: {
      jobid: "",
      title: "",
      descriptions: "",
      test: "",
      totalOpen: 0,
      status: "",
      createdOn: "",
      modifiedOn: "",
      domain: "",
      processflow: { flow1: "test", flow2: "Manager Interview", flow3: "Client1 Interview", flow4: "" },
    },
    defaultItem: {
      jobid: "",
      title: "",
      descriptions: "",
      test: "",
      totalOpen: 0,
      status: "",
      createdOn: "",
      modifiedOn: "",
      domain: "",
      processflow: { flow1: "test", flow2: "Manager Interview", flow3: "Client1 Interview", flow4: "" },
    },
    jobStatus: ["open", "closed"],
    inputrequired: [(v) => !!v || "Input is required"],
    snackbar: false,
    alertflag: false,
    alertmsg: "",
    snackbartext: "",
    editflag: false,
    tests: ["Needed", "Not Needed"],
    roles: "",
    spinner:false,
  }), // end of data

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Job" : "Edit Job"
    },
    ...mapState({
      currentDomain: state=> state.userProfile.domain
    }),
	
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    
  },

  async created() {
    this.roles = await this.$store.state.userProfile

    console.log("Jobs crated", this.roles)
    if (this.roles) {
      if (this.roles.role.hradmin || this.roles.role.readuser) {
        if (this.roles.role.hradmin) {
          // only hradmin can have access to this page.
          this.editflag = true
        }
        this.domain = this.roles.domain
        this.initialize()
      } else {
        alert("Not authorized to access this page , kindly contact support")
        //this.$router.replace({ name: this.roles.menufrom})
        this.$router.push({ name: this.roles.menufrom, params: { roles: this.roles } })
      }
    } else {
      alert("Not authorized to access this page , kindly contact support")
      this.$router.replace({ name: "/login" })
    }
  },
  mounted() {
    jobsRef.where("domain", "==", this.currentDomain).onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
     
          if(!this.notloaded)
          this.updaterealtimedata(this.jobs, change)
       
      }) // end of for each
    }) // end of snapshot
  },

  methods: {
    async initialize() {
      this.jobs = [];
      var ret = await this.loadInitialData(jobsRef, this.domain, this.jobs)
      if (ret != true) {
        this.alertflag = true
        this.alertmsg = ret
        this.notloaded = false
      } 
       
      this.notloaded = false
    },
   
    editItem(item) {
      this.editedIndex = this.jobs.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

     async deleteItem(item) {
      const deletemsg = "Are you sure you want to delete this job?" + item.jobid
      var Ret = await this.commonDeleteItem(item, this.jobs, jobsRef, deletemsg)
      console.log("Return from delete Item", Ret)
      if (Ret == true) {
        this.snackbar = true
        this.snackbartext = "The Jobs has been deleted successfully"
      } else {
        this.alertflag = true
        this.alertmsg = "Error while delete" + Ret
      }
    },
  
    close() {
      this.dialog = false
      this.spinner = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async saveitem() {
      this.spinner = true
      if (this.editedItem.processflow) {
        console.log(
          "prorcess flow object and values",
          this.editedItem.processflow,
          Object.values(this.editedItem.processflow)
        )
        
        var tmpobjectvalues = Object.values(this.editedItem.processflow)
        console.log("processflow",tmpobjectvalues)
        if((this.editedItem.test=="Needed") && (tmpobjectvalues.indexOf("Test")==-1))
        { 
          this.alertflag = true
          this.alertmsg = "Please selecte Test in process flow" 
          this.spinner = false
           return
        }
        const findDuplicates = arr => [...new Set(arr.filter(v => arr.indexOf(v) !== arr.lastIndexOf(v)))];
        if(findDuplicates(tmpobjectvalues).length >0)
        { 
          this.alertflag = true
          this.alertmsg = "Duplicate found in process flow." 
          this.spinner = false
           return
        }
       
      }
      if (this.editedIndex > -1) {
        this.editedItem.modifiedOn = new Date().toISOString().slice(0, 10)
        console.log("inside saveite update", this.editedIndex, this.editedItem)
        Object.assign(this.jobs[this.editedIndex], this.editedItem)

        try {
         
          await jobsRef.doc(this.editedItem.uid).set({
            ...this.editedItem,
          })

        
          this.snackbar = true
          this.snackbartext = "Jobs details updated successfully"
          this.close()
        } catch (error) {
       
          console.log("Error while update :", error)
          this.spinner = false
          this.alertflag = true
          this.alertmsg = "Error while update" + error
        }
      } // end of if
      else {
        this.editedItem.domain = this.domain
        this.editedItem.createdOn = new Date().toISOString().substr(0, 10)
        console.log("inside new item add", this.editedItem)
        try {
           var queryresult = this.jobs.filter((re) => re.jobid == this.editedItem.jobid)
         
          if (queryresult.length == 0) {
            try {
              await jobsRef
                .add(this.editedItem)
                .then((docRef) => {
                  console.log("uid for new record is ", docRef.id)
                  this.editedItem.uid = docRef.id
                })
                .catch((error) => console.error("Error adding Jobs: ", error))

              this.jobs.push(this.editedItem)
              this.snackbar = true
              this.snackbartext = "New Job details added successfully"
            
              this.close()
            } catch (error) {
              this.spinner = false
              this.alertflag = true
              this.alertmsg = "Error while add the new job" + error
            }
          } else {
            this.alertflag = true
            this.alertmsg = "Job-ID already exists , please choose another job id"
            this.spinner = false
          }
        } catch (error) {
          alert("error in add new jobs")
          console.log("error in add new jobs", error)
          this.spinner = false
          this.alertflag = true
          this.alertmsg = "Error in add new jobs" + error
        }
      }
    },
  },
}
</script>
